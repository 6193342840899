/*login*/
.login-logo {
    background-size: 45% 100%;
}

/*header*/
.navbar .navbar-brand {
    padding: 2px 0 2px 0;
}
.navbar .navbar-brand small img {
    height: 40px;
    width: 120px;
}
